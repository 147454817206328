@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-DatasetSetSelector {
  display: flex;
  grid-gap: .75rem;
  flex-wrap: wrap;
}

.unhab-DatasetSetSelectorItem {
  border: 1px solid $borderColor_base;
  border-radius: .25rem;
  cursor: pointer;
  padding: .5rem .75rem;
  position: relative;

  &.is-active {
    background: var(--accent05);
    border-color: var(--accent50);
    border-width: 2px;

    .unhab-DatasetSetSelectorItem-icon .ptr-react-icon {
      display: block;
    }

    .unhab-DatasetItem {
      color: var(--accent55);
      font-weight: 500;
    }
  }

  &:hover {
    background: var(--accent05);
  }
}

.unhab-DatasetSetSelectorItem-icon {
  color: $iconColor_inverted;
  position: absolute;
  top: -.35rem;
  right: -.35rem;

  .ptr-react-icon {
    display: none;
    width: 1rem;
    height: 1rem;
    border-radius: .75rem;
    background: $iconColor_accented;
    padding: .1rem;
    border: 1px solid var(--base05);
  }
}

.unhab-DatasetItem {
  font-size: $a1;
  color: $textColor_base;
}