@use 'sass:math';
@import '../../../../../../styles/index.scss';

.unhab-Attribute-absolute {
	display: flex;
	align-items: baseline;
}

.unhab-Attribute-absolute > div {
	font-size: $b2;
	font-weight: bold;
	color: $textColor_accented;
	margin-right: 0.15rem;
}

.unhab-Attribute-absolute > span {
	font-size: $b1;
	color: $textColor_secondary;
}

.unhab-Attribute-relative {
	display: flex;
	align-items: baseline;
}

.unhab-Attribute-relative > div {
	font-size: $a1;
	font-weight: bold;
	color: $textColor_accented;
	margin-right: 0.15rem;
}

.unhab-Attribute-relative > span {
	font-size: $b1;
	color: $textColor_accented;
}
