@use 'sass:math';
@import '../../../../styles/variables';

.unhab-BenchmarkHeader {
  display: flex;
  grid-gap: 2rem;
  align-items: center;
}

.unhab-BenchmarkHeader-labels {
  display: flex;
  grid-gap: 2rem;
}

.unhab-BenchmarkHeader-labels .ptr-LabeledItem label {
  margin-bottom: -2px;
}

