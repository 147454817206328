@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-DataLayerControl {
	display: flex;
	flex-direction: column;
	padding: 0.2rem 0;
	grid-gap: 0.25rem;
	transition: padding-top 0.15s ease-in-out;

	&.is-active:not(.loading) {
		background: var(--accent05);
		margin: 0 -0.5rem;
		padding: 0.35rem 0.5rem 0.35rem;
		border-top: 1px solid var(--base05);
	}
}

.unhab-DataLayerControl-header {
	display: flex;
	font-size: $b1;
	align-items: center;
	justify-content: space-between;
	grid-gap: 0.25rem;
	justify-content: start;
}

.unhab-DataLayerControl-name {
	display: flex;
	align-items: center;
	color: $textColor_base;
	min-width: 0;
}

.unhab-DataLayerControl-name > span {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.unhab-DataLayerControl-name > span > span {
	color: $textColor_secondary;
	font-size: 0.7rem;
}

.unhab-DataLayerControl.is-active:not(.loading) .unhab-DataLayerControl-name:not(.loading) > span {
	font-weight: bold;
	color: var(--accent50);
}

.unhab-DataLayerControl-tools {
	display: flex;
}

.unhab-DataLayerControlSettings {
	color: $iconColor_base;
	height: 1rem;
	cursor: pointer;

	&.is-active {
		color: $iconColor_accented;
	}
}

.unhab-DataLayerControlSettings > .ptr-react-icon {
	width: 1rem;
	height: 1rem;
}
