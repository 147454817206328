@use 'sass:math';
@import '../../../styles/variables';

.unhab-PeriodSelector {
  display: flex;
  grid-gap: .25rem;
  width: 15rem;
  margin-top: .2rem;
  align-items: center;
}

.unhab-PeriodSelector.is-narrow {
  width: 12rem;
}

.unhab-PeriodSelector > span {
  font-size: $b1;
  color: $textColor_base;
}

.unhab-PeriodSelector-slider {
  height: .2rem;
  border-radius: 5rem;
  flex: 1;
  margin: 0 .4rem;
}

.unhab-PeriodSelector-thumb, {
  margin-top: -0.55rem;
  width: 1.3rem;
  height: 1.25rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
  cursor: pointer;
  outline: none;
}

.unhab-PeriodSelector-thumb:hover {
  background: var(--accent40);
  outline: none;
}

.unhab-PeriodSelector-thumb-0,
.unhab-PeriodSelector-thumb-0:hover,
.unhab-PeriodSelector-thumb-1,
.unhab-PeriodSelector-thumb-1:hover{
  background: transparent;
}
.unhab-PeriodSelector-thumb-0 > div,
.unhab-PeriodSelector-thumb-1 > div {
  position: absolute;
  color: var(--base0);
  background: var(--accent50);
  width: 1.3rem;
  height: 1.25rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
  cursor: pointer;
}

.unhab-PeriodSelector-thumb-0 > div {
  left: -.5rem;
}

.unhab-PeriodSelector-thumb-1 > div {
  right: -.5rem;
}

.unhab-PeriodSelector-thumb-0 > div:hover,
.unhab-PeriodSelector-thumb-1 > div:hover {
  background: var(--accent40);
}

.unhab-PeriodSelector-track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
}

.unhab-PeriodSelector-track-0 {
  background: var(--base30);
}

.unhab-PeriodSelector-track-1 {
  background: $iconColor_accented;
  z-index: 1;
}

.unhab-PeriodSelector-track-2 {
  background: var(--base30);
}
