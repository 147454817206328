@import '../../../../styles/variables';

.unhab-MapControlsWrapper {
	bottom: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;

	> * {
		pointer-events: all;
	}

	// MapInfoElements
	.unhab-MapInfoElements {
		pointer-events: none;
		position: absolute;
		bottom: calc($mapWindowPadding_bottom - .25rem);
		right: calc($mapWindowPadding_right + 2rem);
		display: flex;
		grid-gap: 0.35rem;
		flex-direction: column;
		z-index: 9;
		max-width: calc(100% - 9.3rem);
	}

	&.left .unhab-MapInfoElements {
		right: auto;
		left: calc($mapWindowPadding_left + 2rem);
	}

	// AttributionScaleContainer
	.unhab-AttributionScaleContainer {
		display: flex;
		grid-gap: 0.25rem;
		flex-wrap: wrap;
		padding-left: .5rem;
		align-items: center;

		.ptr-Scale {
			margin-right: .25rem;
		}
	}

	&.right .unhab-AttributionScaleContainer {
		flex-direction: row-reverse;
		padding-left: 0;
		padding-right: .5rem;

		.ptr-MapAttribution {
			text-align: right;
		}

		.ptr-Scale {
			margin-left: .25rem;
			margin-right: 0;
		}
	}

	// Map controls
	.unhab-MapControls {
		position: absolute;
		right: $mapWindowPadding_right;
		bottom: $mapWindowPadding_bottom;
		grid-gap: $mapButtonsGap;
		display: flex;
		flex-direction: column;
		z-index: 9;
	}

	&.left .unhab-MapControls {
		right: auto;
		left: $mapWindowPadding_left;
	}

	// Map tools
	.unhab-MapTools {
		max-width: calc(100% - 5rem);
		position: absolute;
		right: $mapWindowPadding_right;
		top: $mapWindowPadding_top;
		grid-gap: $mapButtonsGap;
		display: flex;
		flex-direction: column;
		z-index: 9;
		align-items: flex-end;
		pointer-events: none;

		&.left {
			right: auto;
			left: $mapWindowPadding_left;
			align-items: flex-start;
		}
	}

	.unhab-MapTools > * {
		pointer-events: all;
	}

	// Map Legend
	.unhab-MapLegendGroup {
		position: absolute;
		right: $mapWindowPadding_right;
		top: $mapWindowPadding_top;
		z-index: 9;
		align-items: flex-end;

		&.left {
			right: auto;
			left: $mapWindowPadding_left;
		}

		&.bottom {
			top: auto;
			bottom: $mapWindowPadding_bottom;
		}
	}
}
