@use 'sass:math';
@import '../../../styles/variables';

.ptr-ColoredLabel {
    font-size: $a0;
    color: #ffffff;
    padding: 0.15rem 0.35rem 0.1rem;
    border-radius: 0.25rem;
    background-color: #aaaaaa;
    text-transform: uppercase;
}