@use 'sass:math';
@import '../../../styles/variables';

.unhab-Header {
	background-color: var(--base10);
	border-bottom: 1px solid var(--base30);
	display: flex;
	grid-gap: 2rem;
	height: $headerHeight;
	z-index: 99;
	box-shadow: $floatingBoxShadow;
}

.unhab-Header-nav {
	display: flex;
	height: $headerHeight;
	align-items: center;
	background: var(--accent50);
	border-radius: 0 0 .25rem 0;
	box-shadow: $floatingBoxShadow;
}

.unhab-Header-content {
	display: flex;
	flex: 1;
	align-items: center;
	grid-gap: m(1);
	padding-top: 2px;
}

.unhab-Header-content-left,
.unhab-Header-content-right {
	display: flex;
	align-items: center;
}

.unhab-Header-content-left {
	flex: 1;
	grid-gap: 1.5rem;
}

.unhab-Header-content-right {
	justify-content: flex-end;
	grid-gap: 1rem;
	margin-right: .75rem;
	display: flex;
}