@use 'sass:math';
@import '../../../styles/variables';

.unhab-SelectedFeaturesControl {
	margin-top: 0.25rem;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 0.5rem;

	> div.unhab-SelectedFeaturesControl-note {
		font-size: $b1;
		line-height: 24px;
		color: $textColor_secondary;
		width: 100%;

		> div {
			height: 24px;
		}
	}
}
