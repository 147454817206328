@use 'sass:math';
@import '~@gisatcz/ptr-core/lib/styles/_variables';

/* --- General --- */
$lightAccent0: hsl(40, 100%, 99.41%);
$lightAccent0rgb: 255, 254, 252;
$lightAccent05: hsl(197.14, 67.74%, 93.92%);
$lightAccent10: hsl(199.09, 75.86%, 88.63%);
$lightAccent15: hsl(200.6, 78.82%, 83.33%);
$lightAccent20: hsl(200, 78.95%, 77.65%);
$lightAccent25: hsl(200.18, 80.14%, 72.35%);
$lightAccent30: hsl(200.15, 81.07%, 66.86%);
$lightAccent35: hsl(200.25, 81.63%, 61.57%);
$lightAccent40: hsl(200.54, 82.14%, 56.08%);
$lightAccent45: hsl(200.58, 82.47%, 50.78%);
$lightAccent50: hsl(200.35, 100%, 45.1%);
$lightAccent50rgb: 0, 152, 230;
$lightAccent55: hsl(200.29, 100%, 40.59%);
$lightAccent60: hsl(200.33, 98.92%, 36.27%);
$lightAccent65: hsl(200.25, 98.77%, 31.76%);
$lightAccent70: hsl(200.29, 97.14%, 27.45%);
$lightAccent75: hsl(199.65, 96.58%, 22.94%);
$lightAccent80: hsl(199.33, 95.74%, 18.43%);
$lightAccent85: hsl(199.09, 91.67%, 14.12%);
$lightAccent90: hsl(198.14, 87.76%, 9.61%);
$lightAccent95: hsl(195.79, 70.37%, 5.29%);
$lightAccent100: hsl(45, 100%, 0.78%);
$lightAccent100rgb: 4, 3, 0;

$darkAccent0: hsl(45, 100%, 0.78%);
$darkAccent0rgb: 4, 3, 0;
$darkAccent05: hsl(195.79, 70.37%, 5.29%);
$darkAccent10: hsl(198.14, 87.76%, 9.61%);
$darkAccent15: hsl(199.09, 91.67%, 14.12%);
$darkAccent20: hsl(199.33, 95.74%, 18.43%);
$darkAccent25: hsl(199.65, 96.58%, 22.94%);
$darkAccent30: hsl(200.29, 97.14%, 27.45%);
$darkAccent35: hsl(200.25, 98.77%, 31.76%);
$darkAccent40: hsl(200.33, 98.92%, 36.27%);
$darkAccent45: hsl(200.29, 100%, 40.59%);
$darkAccent50: hsl(200.35, 100%, 45.1%);
$darkAccent50rgb: 0, 152, 230;
$darkAccent55: hsl(200.58, 82.47%, 50.78%);
$darkAccent60: hsl(200.54, 82.14%, 56.08%);
$darkAccent65: hsl(200.25, 81.63%, 61.57%);
$darkAccent70: hsl(200.15, 81.07%, 66.86%);
$darkAccent75: hsl(200.18, 80.14%, 72.35%);
$darkAccent80: hsl(200, 78.95%, 77.65%);
$darkAccent85: hsl(200.6, 78.82%, 83.33%);
$darkAccent90: hsl(199.09, 75.86%, 88.63%);
$darkAccent95: hsl(197.14, 67.74%, 93.92%);
$darkAccent100: hsl(40, 100%, 99.41%);
$darkAccent100rgb: 255, 254, 252;

//Colors
$hoverBackgroundColor: rgba(var(--base50rgb), 0.12);
$hoverBackgroundColor-dark: rgba(var(--base50rgb), 0.25);

$iconColor_base: var(--base80);
$iconColor_secondary: var(--base50);
$iconColor_accented: var(--accent50);
$iconColor_disabled: var(--base25);
$iconColor_inverted: var(--accent0);

$textColor_base: var(--base80);
$textColor_secondary: var(--base50);
$textColor_tertiary: var(--base40);
$textColor_accented: var(--accent50);

$borderColor_base: var(--base50);
$borderColor_secondary: var(--base25);

// Sizes
$iconSize: 1.25rem;
$iconToolSize_medium: 2rem;

// Floating elements
$floatingBoxBackground: rgba(var(--base0rgb), 0.9);
$floatingBoxShadow: 0 3px 7px rgba(0, 0, 0, 0.15);
$floatingElementShadow: 0 2px 5px rgba(0, 0, 0, 0.35);

@mixin frozenGlassEffect {
	backdrop-filter: blur(6px) saturate(180%);
	-webkit-backdrop-filter: blur(6px) saturate(180%);
}

// Components
// ------------
// Header
$headerHeight: 3.25rem;

// Maps
$mapBorderWidth: 0.05rem;
$mapBorder: $mapBorderWidth solid var(--base30); // the width in rem fixes the ugly white space between border and content in Chrome
$mapWindowPadding_top: 0.9rem;
$mapWindowPadding_left: 0.75rem;
$mapWindowPadding_right: 0.75rem;
$mapWindowPadding_bottom: 0.75rem;
$mapButtonsGap: 0.6rem;

// Side panel
$sidePanel_padding: 1rem;


:export {
	a1: $a1;
	b1: $b1;
	b2: $b2;

	textColorPrimary: $textColor_base;
	textColorSecondary: $textColor_secondary;
	textColorAccented: $textColor_accented;

	iconColorBase: $iconColor_base;
	iconColorSecondary: $iconColor_secondary;
	iconColorAccented: $iconColor_accented;
	iconColorDisabled: $iconColor_disabled;

	borderColorBase: $borderColor_base;
	borderColorSecondary: $borderColor_secondary;
}
