@use 'sass:math';
@import '../../../styles/variables';

.unhab-Map {
	flex: 1;
	height: 100%;
}

// Map set
.esaUtepUnHabitat .ptr-map-set {
	position: relative;
	z-index: 2;
	margin-top: -$mapBorderWidth;
	height: calc(100% + $mapBorderWidth);
}

.esaUtepUnHabitat .ptr-map-grid .ptr-map-grid-cell .ptr-map {
	border: 0;
}

.esaUtepUnHabitat .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
	border-top: $mapBorder;
}

.esaUtepUnHabitat .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
	border-left: $mapBorder;
}

.ptr-map-controls-wrapper {
	z-index: auto;
}

// Map set controls
.unhab-CompareMapsControl-wrapper,
.unhab-LockMapsControl-wrapper {
	position: absolute;
	z-index: 9;
}

.unhab-CompareMapsControl-wrapper {
	top: $mapWindowPadding_top;
	width: 6rem;
	left: calc(50% - 2.9rem);
}
.unhab-CompareMapsControl-wrapper .ptr-button {
	width: 5.8rem;
}

.unhab-LockMapsControl-wrapper {
	top: calc($mapWindowPadding_top + $mapButtonsGap + 1.5rem);
	left: calc(50% - 3.5rem);
}

.unhab-LockMapsControl-wrapper .ptr-button {
	width: 7rem;
}

// Map slider
.unhab-CompareSlider {
	margin-top: -$mapBorderWidth;
	height: calc(100% + $mapBorderWidth);
}

div[data-rcs='handle-container'] {
	z-index: 29;

	.ptr-IconTool {
		bottom: 40%;
	}
}

div[data-rcs='clip-item'] {
	z-index: 28;
}

.ptr-map-controls-wrapper {
	z-index: auto;
}
