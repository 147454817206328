@use 'sass:math';
@import '../../../../styles/variables';

.unhab-StatisticsSection {
  display: flex;
  flex-direction: column;
  grid-gap: .15rem;
  //border-bottom: 1px solid $borderColor_secondary;

  &.is-open:first-child {
    //border-top: 1px solid $borderColor_secondary;
  }

  &.is-open {
    //border-bottom: 1px solid $borderColor_secondary;
  }

  &.is-open .unhab-StatisticsSection-header {

  }

  &.is-open .unhab-StatisticsSection-content {
    display: flex;
    padding-bottom: m(1);
  }
}

.unhab-StatisticsSection-header {
  font-size: $b1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .1rem 0;
  transition: padding-top .15s ease-in-out;

  &:hover .unhab-StatisticsSection-title,
  &:hover .unhab-StatisticsSection-control {
    color: $textColor_accented;
  }
}

.unhab-StatisticsSection-title {
  font-weight: bold;
  color: $textColor_base;
  margin: 0;
}

.unhab-StatisticsSection-control {
  height: 1.25rem;
  width: 1.15rem;
  color: $iconColor_base;
}

.unhab-StatisticsSection-content {
  display: none;
  flex-direction: column;
  padding: 0;
  grid-gap: .5rem;
}