@use 'sass:math';
@import '../../../styles/variables';

.ptr-LabeledItem {
  display: flex;
  flex-direction: column;
}

.ptr-LabeledItem > label {
  font-size: .75rem;
  color: $textColor_secondary;
  text-transform: uppercase;
  font-weight: 400;
}

.ptr-LabeledItem-content {
  margin-top: -.1rem;
  font-weight: bold;
  color: $textColor_base;
  display: flex;
  grid-gap: .25rem;
}

.ptr-LabeledItem-content .divider {
  width: .1rem;
  background: $borderColor_secondary;
  margin: 3px;
}