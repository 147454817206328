@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-DataLayersGroup {
	display: flex;
	flex-direction: column;
	grid-gap: 0.15rem;

	&.is-open > .unhab-DataLayersGroup-content {
		display: flex;
	}
	&.is-secondary {
		margin-left: 0.75rem;
	}

	&.is-secondary .unhab-DataLayersGroup-header {
		text-transform: none;
	}

	&.is-highlighted .unhab-DataLayersGroup-header {
		color: $textColor_accented;
	}
}

.unhab-DataLayersGroup-header {
	font-size: $b1;
	color: $textColor_secondary;
	text-transform: uppercase;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 1.25rem;
}

.unhab-DataLayersGroup-title {
	padding-top: 0.15rem;
	font-weight: bold;
}

.unhab-DataLayersGroup-control {
	height: 1.25rem;
	width: 1.15rem;
}

.unhab-DataLayersGroup-content {
	display: none;
	flex-direction: column;
	padding-bottom: 0.25rem;

	> .unhab-DataLayersGroup {
		margin-left: 0.4rem;
	}
}

.unhab-DataLayersGroup-selected .unhab-DataLayersGroup-content {
	border-bottom: 1px solid var(--base25);
}
