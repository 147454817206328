@use 'sass:math';
@import '../../../styles/variables';

.ptr-Form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  &.is-small {
    grid-gap: .75rem;
  }

  &.is-small .ptr-FormItemLabel {
    font-size: .75rem;
    font-weight: 400;
  }

  &.is-small .ptr-FormItem {
    max-width: 20rem;
  }

  &.is-small .ptr-FormFooter {
    margin-top: .25rem;
    grid-gap: .75rem;
  }
}

.ptr-FormItem {
  display: flex;
  flex-direction: column;
}

.ptr-FormItemLabel {
  font-size: $b1;
  font-weight: bold;
  text-transform: uppercase;
  color: $textColor_secondary;
}

.ptr-FormFooter {
  margin-top: .75rem;
  display: flex;
  grid-gap: 1rem;
}