@import '../../../../styles/variables';

.ptr-MapLayoutControl-button.small {

}

.ptr-MapLayoutControl-button.small .ptr-react-icon {
  width: 1.35rem;
  height: 1.35rem;
  margin: 0;
  padding: 0;
}

.ptr-MapLayoutControl-button.small .ptr-react-icon:nth-child(2) {
  margin-left: -8px;
}