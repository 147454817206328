@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-MapTooltip-title {
	font-size: $a1;
	font-weight: bold;
}

.unhab-MapTooltip-subtitle {
	font-size: $b1;
	color: var(--base40);
}

.unhab-MapTooltip-attributeSet {
	font-size: $b1;
	margin-top: 0.5rem;
	font-weight: bold;
}

.unhab-MapTooltip-attributeSet > span {
	font-weight: normal;
	color: var(--base40);
}

.unhab-MapTooltip-attributeSetValues {
	display: flex;
	grid-gap: 0.5rem;
}

.unhab-MapTooltip-year {
	font-size: $a1;
}

.unhab-MapTooltip-attributeSetValues .unhab-Attribute-absolute > div,
.unhab-MapTooltip-attributeSetValues .unhab-Attribute-relative > div {
	font-size: $a1;
	color: var(--base20);
}

.unhab-MapTooltip-attributeSetValues .unhab-Attribute-absolute > span,
.unhab-MapTooltip-attributeSetValues .unhab-Attribute-relative > span {
	color: var(--base40);
}
