@use 'sass:math';
@import '../../../../../styles/variables';

// Map layer legend
.ptr-MapLayerLegend {
  font-size: $b1;
  display: flex;
  flex-direction: column;
  grid-gap: .25rem;
  border-bottom: 1px solid var(--base20);
  padding-bottom: .75rem;

  &.expandable{
    cursor: pointer;
  }
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

// Title
.ptr-MapLegendTitle {
  line-height: 1;
  display: flex;
}

.ptr-MapLegendTitle-name {
  font-weight: bold;
  font-size: $b1;
  color: $textColor_secondary;
  display: flex;
  flex-direction: column;

  &:first-letter {
    text-transform: uppercase;
  }
}

.ptr-MapLegendTitle:first-letter {
  text-transform: uppercase;
}

.ptr-MapLegendTitle-unit {
  font-size: $b1;
  margin-left: 0.25rem;
  color: $textColor_secondary;
}

// Scale
.ptr-MapLegendScale {
  display: flex;
  flex: 1;
  align-items: center;

  &.is-expanded{
    flex-wrap: wrap;
    grid-gap: .15rem;
  }
}

.ptr-MapLegendScale > span {
  font-size: $b1;
  line-height: 1;
  color: $textColor_secondary;
}

.ptr-MapLegendScale > div {
  flex: 1;
  height: 0.75rem;
  margin: 0 0.25rem;
}

.ptr-MapLegendScale > img {
  height: 0.65rem;
  width: 15rem;
  margin: 0 0.25rem;
}

// Classes
.ptr-MapLegendClasses {
  display: flex;
  flex: 1;

  &.is-expanded {
    flex-direction: column;
    grid-gap: 0.25rem;
  }
}

// Values
.ptr-MapLegendValues {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  grid-gap: 0.35rem;

  &.is-expanded {
    flex-direction: column;
  }
}

// Value item
.ptr-MapLegendValueItem,
.ptr-MapLegendClassItem {
  display: flex;
  grid-gap: 0.25rem;
  align-items: center;
}

.ptr-MapLegendValueItem > div {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.5rem;
}

.ptr-MapLegendValueItem > span,
.ptr-MapLegendClassItem > span {
  font-size: .7rem;
  line-height: 1;
  color: $textColor_secondary;
}

// Class item
.ptr-MapLegendClassItem > div {
  width: 1.25rem;
  height: 0.75rem;
}

// Classes Bar
.ptr-MapLegendClassesBar {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
}
.ptr-MapLegendClassesBar > img {
  height: 0.65rem;
  width: 15rem;
  margin: 0 0.25rem;
}

.ptr-MapLegendClassesBar-label {
  font-size: $b1;
  line-height: 1;
  color: $textColor_secondary;
}

.ptr-MapLegendClassesBar-bar {
  display: flex;
  flex: 1;
  margin: 0 0.25rem;
  height: 100%;
}

.ptr-MapLegendClassesBar-bar > div {
  flex: 1;
  height: 0.75rem;
}

.ptr-MapLegendClassesBar .ptr-MapLegendClassItem {
  margin-right: 0.75rem;
}