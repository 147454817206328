@use 'sass:math';
@import '../../../../styles/variables';

.unhab-SelectedFeatureControl {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.05rem 0 0 0.5rem;
	border: 1px solid $borderColor_base;
	border-radius: 1rem;

	&.unhab-SelectedFeatureControl-hoverable {
		cursor: zoom-in;
	}
}

.unhab-SelectedFeatureControl-tools .ptr-button.small {
	padding: 0 0.15rem;
	border-radius: 1rem;
	margin-left: 0.15rem;
}

.unhab-SelectedFeatureControl-tools .ptr-button:after {
	border-radius: 1rem;
}
