@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-XCubePointControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .05rem 0 0 .5rem;
  border: 1px solid $borderColor_base;
  border-radius: 1rem;
  font-size: $b1;
  line-height: 1;
  color: var(--base90);
}

.unhab-XCubePointControl-tools .ptr-button.small {
  padding: 0 .15rem;
  border-radius: 1rem;
  margin-left: .15rem;
}

.unhab-XCubePointControl-tools .ptr-button:after {
  border-radius: 1rem;
}