@use 'sass:math';
@import '../../../styles/variables';

.ptr-IndicatorBoxSet > .unhab-IndicatorBox {
  padding: .25rem .5rem;
}

.ptr-IndicatorBoxSet > .unhab-IndicatorBox:first-child {
  padding-top: .5rem;
}

.ptr-IndicatorBoxSet > .unhab-IndicatorBox:last-child {
  padding-bottom: .5rem;
}

.ptr-IndicatorBoxSet > .unhab-IndicatorBox:not(:last-child) {
  border-bottom: 1px solid var(--base15);
}

.ptr-IndicatorBoxSet .ptr-BoxTitle {
  font-size: $b1;
}

.unhab-IndicatorBox {
  padding: .5rem;
}

.unhab-IndicatorBox-body {
  display: flex;
  flex-direction: column;
}

.unhab-IndicatorBoxRow {
  display: flex;
  align-items: center;
  grid-gap: .5rem;
  justify-content: space-between;
  padding-right: .25rem;
}

.unhab-IndicatorBoxValues {
  display: flex;
  grid-gap: .5rem;
}

.unhab-IndicatorBoxValue {
  display: flex;
  grid-gap: .15rem;
  min-width: 3.5rem;
  justify-content: flex-end;
  align-items: baseline;
}

.unhab-IndicatorBoxValue > div {
  color: $textColor_base;
  font-weight: bold;
  font-size: $a1;
  line-height: 1;
}

.unhab-IndicatorBoxValue > span,
.unhab-IndicatorBoxValue > div > span {
  color: $textColor_tertiary;
  font-size: $b1;
}