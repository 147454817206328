@use 'sass:math';
@import '../../../styles/variables';

.unhab-AddSelectedAreaControl {
	display: none;
	flex-direction: column;

	&.is-open {
		display: flex;
		padding: 1rem;
		background: var(--base15);
		margin-top: .75rem;
	}
}
