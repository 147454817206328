@use 'sass:math';
@import '../../../../styles/variables';

.ptr-MapConfigurationControl {
  position: relative;
}

// Header
.ptr-MapConfigurationControl-header {
  display: flex;
  align-items: center;
}

.ptr-MapConfigurationControl.is-right .ptr-MapConfigurationControl-header {
  flex-direction: row-reverse;
}

.ptr-MapConfigurationControl-header > .ptr-button {
  min-width: max-content;
  position: relative;
  z-index: 1;
}

.ptr-MapConfigurationControl-detail {
  position: relative;
  z-index: 0;
  font-size: $b1;
  line-height: 1;
  padding: .30rem .5rem .2rem .65rem;
  margin-left: -.15rem;
  font-style: italic;
  color: $textColor_base;
  background: rgba(var(--base0rgb), .5);
  @include frozenGlassEffect();
}

.ptr-MapConfigurationControl.is-right .ptr-MapConfigurationControl-detail {
  padding: .35rem .65rem .2rem .5rem;
  margin-right: -.15rem;
  margin-left: 0;
}

// Content
.ptr-MapConfigurationControl-content {
  display: none;
  position: absolute;
  top: 2rem;
  left: 0;
  background: var(--base05);
  border-radius: .25rem;
  padding: .5rem;
  box-shadow: $floatingBoxShadow;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;

  &.is-open {
    display: block;
  }

  &.show-top {
    bottom: 2rem;
    top: auto;
  }
}

.ptr-MapConfigurationControl.is-right .ptr-MapConfigurationControl-content{
  left: auto;
  right: 0;
}