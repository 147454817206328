@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-SortLayersItem {
	font-size: $b1;
	color: $textColor_base;
	align-items: center;
}

.unhab-SortLayersItem a {
	color: $iconColor_base;
	height: 1.15rem;
	margin-right: 0.05rem;
	margin-left: -0.25rem;

	&:hover {
		cursor: grab;
		color: $iconColor_base;
	}
}

.unhab-SortLayersItem .ptr-react-icon {
	width: 1.15rem;
	height: 1.15rem;
}

.unhab-SortLayersItem span {
	flex-grow: 1;
	display: block;
	padding-top: 0.15rem;
}
