@import '../../../../styles/variables';

.ptr-DeckTooltip {
	// max-width: 20rem;
	background-color: transparent;
	border: none;
	box-shadow: none;

	> div {
		@include frozenGlassEffect();
		background-color: rgba(var(--base100rgb), 0.8);
		border-radius: 0.25rem;
		box-shadow: $floatingElementShadow;
		color: var(--base20);
	}
}

.unhab-MapTooltip {
	padding: 0.5rem 1rem 0.5rem 0.65rem;
}
