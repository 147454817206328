@use 'sass:math';
@import '../../../styles/variables';

.ptr-Box.unhab-Chart {
  background: var(--base0);
  border-radius: .15rem;
  padding: 0;
}

.unhab-Chart-header {
  display: flex;
  justify-content: space-between;
  padding: .5rem 0 0 .5rem;
}

.unhab-Chart-subtitle {
  color: $textColor_secondary;
  font-size: $b1;
  line-height: 1.15;
}

.unhab-Chart-body {
  padding: .5rem;
}