@use 'sass:math';
@import '../../../../styles/variables';

.unhab-BenchmarkConfiguration {
  padding: m(1);
  min-width: 40rem;
  max-width: 90%;
}

.unhab-BenchmarkConfiguration-title {
  margin: 0 0 m(1);
}

.unhab-BenchmarkConfiguration-areas {
  display: flex;
  grid-gap: .75rem;
}

.unhab-BenchmarkConfiguration-area {
  flex: 1;
  max-width: 60%;
}

.unhab-BenchmarkConfiguration-area .ptr-Form {
  background: var(--base05);
  padding: 1rem;
}