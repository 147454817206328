@use 'sass:math';
@import '../../../styles/variables';

.unhab-DatasetSelect {
  display: flex;
}

.unhab-DatasetSelect > .ptr-Select {
  min-width: 20rem;
}

.unhab-DatasetSelect-option {
  display: flex;
  align-items: center;
  grid-gap: .5rem;
}