@use 'sass:math';
@import '../../../styles/variables';

.ptr-SidePanel {
  position: relative;
  z-index: 10;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  background: var(--base10);
}

.ptr-SidePanel-control.ptr-button {
  position: absolute;
  top: calc(50% - 1.25rem);
  left: -1.6rem;
  display: flex;
  align-items: center;
  padding-bottom: .1rem;
  z-index: 1;
}

.ptr-SidePanel-content {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--base10);
}

.ptr-SidePanelHeader {
  display: flex;
  flex-direction: column;
  grid-gap: .75rem;
  padding: $sidePanel_padding;
  border-bottom: 1px solid $borderColor_secondary;
}

.ptr-SidePanelBody {
  margin: 0;
  padding: $sidePanel_padding;
  display: flex;
  flex-direction: column;
  grid-gap: m(1);
  overflow-y: auto;
}